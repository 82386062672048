export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container'>
        <div className='row'>
          <div className='col-md-4 left studio'>
              <img className="logo" src="/img/logo.svg" alt="studio365" />
              <p className="footer-text">Nós reimaginamos o futuro com Microsoft 365</p>
              <a target="_blank" href="https://www.linkedin.com/company/top365/">
                <img className="linkedin" src="/img/linkedin.png" alt="linkedin" />
              </a>
          </div>
          <div className='col-md-4 right partner'>
            <a 
              href="https://news.microsoft.com/pt-br/microsoft-anuncia-vencedores-e-finalistas-do-premio-partner-of-the-year-deste-ano/"
              target="_blank"
            >
              <img  src="/img/logo-partner.png" alt="Partner" />
            </a>
            
          </div>
          <div className="clear text-center copyright">
            <p>© 2022, Studio 365 | Todos os direitos reservados</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}
