import React, { useRef } from "react";
import { Fade, Slide } from 'react-slideshow-image';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const sei365Images = [
  'images/slide_2.jpg',
  'images/slide_3.jpg',
  'images/slide_4.jpg'
];

const balcaoVirtualImages = [
  '/img/balcao-usuarios.png',
  '/img/balcao-atendente.png',
];

const helloImages = [
  '/img/hello-usuarios.png',
  '/img/hello-autores.png',
];

const properties = {
  duration: 3000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: false,
  pauseOnHover: false,
}

export const Productions = (props) => {
  const slideRefBalcao = useRef();
  const slideRefBalcaoMobile = useRef();
  const slideRefHello = useRef();
  const slideRefHelloMobile = useRef();

  const [focusBalcao, setFocusBalcao] = React.useState(false);
  const [focusHello, setFocusHello] = React.useState(false);
  const [focusSei, setFocusSei] = React.useState(false);

  const backBalcaoVirtual = () => {
    slideRefBalcao.current.goTo(0);
    document.getElementById("usuarios").classList.add("active");
    document.getElementById("atendentes").classList.remove("active");
  }

  const nextBalcaoVirtual = () => {
    slideRefBalcao.current.goTo(1);
    document.getElementById("usuarios").classList.remove("active");
    document.getElementById("atendentes").classList.add("active");
  }

  const backBalcaoVirtualMobile = () => {
    slideRefBalcaoMobile.current.goTo(0);
    document.getElementById("usuarios-mobile").classList.add("active");
    document.getElementById("atendentes-mobile").classList.remove("active");
  }

  const nextBalcaoVirtualMobile = () => {
    slideRefBalcaoMobile.current.goTo(1);
    document.getElementById("usuarios-mobile").classList.remove("active");
    document.getElementById("atendentes-mobile").classList.add("active");
  }

  const backHello = () => {
    slideRefHello.current.goTo(0);
    document.getElementById("usuarios-hello").classList.add("active");
    document.getElementById("autores").classList.remove("active");
  }

  const nextHello = () => {
    slideRefHello.current.goTo(1);
    document.getElementById("usuarios-hello").classList.remove("active");
    document.getElementById("autores").classList.add("active");
  }
  
  const backHelloMobile = () => {
    slideRefHelloMobile.current.goTo(0);
    document.getElementById("usuarios-hello-mobile").classList.add("active");
    document.getElementById("autores-mobile").classList.remove("active");
  }

  const nextHelloMobile = () => {
    slideRefHelloMobile.current.goTo(1);
    document.getElementById("usuarios-hello-mobile").classList.remove("active");
    document.getElementById("autores-mobile").classList.add("active");
  }

  return (
    <div id='productions'>
        <div className="desktop">
          <div className='bg-balcao'>
            <div className='container balcao-virtual'>
              <div className='title-production-balcao-virtual'>
                <h2>
                  NOSSAS <span className="bolder">PRODUÇÕES</span>
                </h2>
              </div>
              <div className='row'>

                <div className='col-md-6 boxes balcao'>
                  <div className="nav-bar-production">
                    <ul>
                      <li>
                        <a id="usuarios" onClick={backBalcaoVirtual} className='page-scroll active'>
                          USUÁRIOS
                        </a>
                      </li>                    
                      <li>
                        <a id="atendentes" onClick={nextBalcaoVirtual} className='page-scroll'>
                          ATENDENTES
                        </a>  
                      </li>
                    </ul>
                  </div>
                    
                  <Fade 
                    ref={slideRefBalcao} 
                    {...properties} 
                    onChange={(e) => {
                      if(e == 1){
                        document.getElementById("usuarios").classList.add("active");
                        document.getElementById("atendentes").classList.remove("active");
                      }
                      else {
                        document.getElementById("usuarios").classList.remove("active");
                        document.getElementById("atendentes").classList.add("active");
                      }
                    }}>
                    <div className="each-slide">
                      <div style={{'backgroundImage': `url(${balcaoVirtualImages[0]})`}}></div>
                    </div>
                    <div className="each-slide">
                      <div style={{'backgroundImage': `url(${balcaoVirtualImages[1]})`}}></div>
                    </div>                
                  </Fade>
                    
                </div>

                <div className='col-md-5 boxes description-production'>
                  <div>
                    <h2>
                        <span className="bolder">BALCÃO</span> VIRTUAL
                        <img className="icon" src="/img/balcao-icon.png" alt="Balcão Virtual" />{' '}
                    </h2>
                    
                    <div className='text-production'>
                      <p className="bolder">Reimaginando experiências de atendimento.</p>
                      <p>O Balcão Virtual é um aplicativo para o Microsoft Teams capaz de transformar experiências de atendimento internas (colaboradores) ou externas (cidadãos ou consumidores), através de uma jornada rica e completa, presente desde o pre-atendimento, atendimento e pós-atendimento.</p>
                    </div>                
                    
                    <div className="metrics-container">
                      <h2 className="metrics-production">
                        <CountUp 
                          className="bolder"
                          start={focusBalcao ? 0 : null} 
                          end={3800000} 
                          duration={2.5}
                          prefix="+"

                          separator="."
                        >
                            {({ countUpRef }) => (
                                <VisibilitySensor onChange={(isVisible) => {
                                    if (isVisible) { setFocusBalcao(true); }
                                }}>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>
                      </h2>
                      <p>Solicitações de atendimento registradas</p>
                    </div>
                    

                    <a
                      href='https://balcao-virtual-website.azurewebsites.net/'
                      className='btn btn-custom btn-know-more btn-lg page-scroll'
                      target="_blank"
                    >
                      Saiba mais <span className="arrow">→</span>
                    </a>{' '}

                  </div>
                </div>

              </div>
            </div>
          </div>
          
          <div className='bg-hello'>
            <div className='container hello'>
              <div className='row'>
                <div className='col-md-5 boxes description-production hello'>
                  <div>
                    <h2>
                        COMPANY <span className="bolder">HELLO</span>
                        <img className='icon' src="/img/hello-icon.png" alt="Company Hello" />{' '}
                    </h2>
                    
                    <div className='text-production'>
                      <p className="bolder">A versão profissional do Company Communicator.</p>
                      <p>O Company Hello transforma a comunicação interna através do uso do Microsoft Teams, modernizando a experiência da comunicação e garantindo o uso do Microsoft Teams como uma poderosa ferramenta de comunicação corporativa.</p>
                    </div>                
                    
                    <div className="metrics-container">
                      <h2 className="metrics-production">
                        <CountUp 
                          className="bolder"
                          start={focusHello ? 0 : null} 
                          end={23700000} 
                          duration={2.5}
                          prefix="+"

                          separator="."
                        >
                            {({ countUpRef }) => (
                                <VisibilitySensor onChange={(isVisible) => {
                                    if (isVisible) { setFocusHello(true); }
                                }}>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>
                      </h2>
                      <p>De comunicados enviados</p>
                    </div>
                    

                    <a
                      href='https://hello-website.azurewebsites.net/'
                      className='btn btn-custom btn-know-more btn-lg page-scroll'
                      target="_blank"
                    >
                      Saiba mais <span className="arrow">→</span>
                    </a>{' '}
                  </div>
                </div>

                <div className='col-md-6 boxes hello-slider'>
                  <div className="row nav-bar-production">
                    <ul>
                      <li>
                        <a id="usuarios-hello" onClick={backHello} className='page-scroll active'>
                          USUÁRIOS
                        </a>
                      </li>                    
                      <li>
                        <a id="autores" onClick={nextHello} className='page-scroll'>
                          AUTORES
                        </a>  
                      </li>
                    </ul>
                  </div>
                    
                  <Fade 
                    ref={slideRefHello} 
                    {...properties}
                    onChange={(e) => {
                      if(e == 1){
                        document.getElementById("usuarios-hello").classList.add("active");
                        document.getElementById("autores").classList.remove("active");
                      }
                      else {
                        document.getElementById("usuarios-hello").classList.remove("active");
                        document.getElementById("autores").classList.add("active");
                      }
                    }}
                  >
                    <div className="each-slide">
                      <div style={{'backgroundImage': `url(${helloImages[0]})`}}></div>
                    </div>
                    <div className="each-slide">
                      <div style={{'backgroundImage': `url(${helloImages[1]})`}}></div>
                    </div>                
                  </Fade>                  
                </div>
              </div>
            </div>
          </div>

          <div className='bg-sei365'>
            <div className='container sei365'>
              <div className='row'>
                <div className='col-md-6 boxes slideshow'>
                  {' '}
                  <img src="/img/sei-teams.png" alt="SEI365" />{' '} 
                </div>
                <div className='col-md-5 boxes description-production'>
                  <div>
                    <h2>
                      <span className="bolder">SEI 365</span>
                      <img className='icon icon-sei' src="/img/sei-icon.png" alt="SEI 365" />{' '}
                    </h2>
                    <p className="bolder">Inovação e transformação digital para o setor público</p>
                    <p>É um software web que integra o SEI - Sistema Eletrônico de Informações (SEI) com a Plataforma Microsoft 365, utilizada por grande parte dos órgãos e instituições públicas brasileiras para controlar digitalmente processos administrativos e documentos com a ajuda de um bot.</p>                  
                    <a
                      href='https://www.sei365.com.br/'
                      className='btn btn-custom btn-know-more btn-lg page-scroll'
                      target="_blank"
                    >
                      Saiba mais <span className="arrow">→</span>
                    </a>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='container products-info'>
            <div className='row'>
              <div className='col-md-4 boxes'>
                <h3><span className="bolder">Oficinas</span> de trabalho híbrido</h3>
                <p>Empoderando empresas com melhores práticas e recomendações para explorar, com segurança e confiança, todo potencial de colaboração e produtividade do Microsoft 365 para a nova realidade de trabalho.</p>
                <a href="#contact-us">Quero conhecer <span className="arrow">→</span></a>
              </div>
              <div className='col-md-4 boxes'>
                <h3><span className="bolder">Oficina</span> inteligência do trabalho com viva</h3>
                <p>Com o Microsoft Viva é possível simplificar processos e concentrar informações em um só lugar. O grande pilar da inteligencia de trabalho são os colaboradores, com o Viva é possível equilibrar produtividade e bem-estar através de insights poderosos.</p>
                <a href="#contact-us">Saiba mais <span className="arrow">→</span></a>
              </div>
              <div className='col-md-4 boxes'>
                <h3><span className="bolder">Assistente</span> inteligente</h3>
                <p>O Assistente Inteligente (ChatBot) integrado ao Microsoft Teams é uma solução com o objetivo de modernizar a experiência de interação com informações corporativas para os colaboradores, através de um canal moderno, dinâmico e inteligente.</p>
                <a href="#contact-us">Saiba mais <span className="arrow">→</span></a>
              </div>
            </div>
          </div>
        </div>  

        <div className="mobile">          
          <div className='container balcao-virtual'>
            <div className='title-production-balcao-virtual'>
              <h2>
                NOSSAS <span className="bolder">PRODUÇÕES</span>
              </h2>
            </div>
            <div className='row'>
              <div className='col-md-12 boxes balcao'>
                <div>
                  <h2>
                      <span className="bolder">BALCÃO</span> VIRTUAL
                      <img className="icon" src="/img/balcao-icon.png" alt="Balcão Virtual" />{' '}
                  </h2>
                </div>                
                
                <div className="nav-bar-production">
                  <ul>
                    <li>
                      <a id="usuarios-mobile" onClick={backBalcaoVirtualMobile} className='page-scroll active'>
                        USUÁRIOS
                      </a>
                    </li>                    
                    <li>
                      <a id="atendentes-mobile" onClick={nextBalcaoVirtualMobile} className='page-scroll'>
                        ATENDENTES
                      </a>  
                    </li>
                  </ul>
                </div>
                  
                <Fade 
                  ref={slideRefBalcaoMobile} 
                  {...properties} 
                  onChange={(e) => {
                    if(e == 1){
                      document.getElementById("usuarios-mobile").classList.add("active");
                      document.getElementById("atendentes-mobile").classList.remove("active");
                    }
                    else {
                      document.getElementById("usuarios-mobile").classList.remove("active");
                      document.getElementById("atendentes-mobile").classList.add("active");
                    }
                  }}>
                  <div className="each-slide">
                    <div style={{'backgroundImage': `url(${balcaoVirtualImages[0]})`}}></div>
                  </div>
                  <div className="each-slide">
                    <div style={{'backgroundImage': `url(${balcaoVirtualImages[1]})`}}></div>
                  </div>                
                </Fade>

                <div>
                  <div className='text-production'>
                    <p className="bolder">Reimaginando experiências de atendimento.</p>
                    <p>O Balcão Virtual é um aplicativo para o Microsoft Teams capaz de transformar experiências de atendimento internas (colaboradores) ou externas (cidadãos ou consumidores), através de uma jornada rica e completa, presente desde o pr, atendimento e pós-atendimento.</p>
                    </div>                

                    <div className="metrics-container">
                        <h3 className="metrics-production bolder">
                        <CountUp 
                          className="bolder"
                          start={focusBalcao ? 0 : null} 
                          end={3800000} 
                          duration={2.5}
                          prefix="+"

                          separator="."
                        >
                            {({ countUpRef }) => (
                                <VisibilitySensor onChange={(isVisible) => {
                                    if (isVisible) { setFocusBalcao(true); }
                                }}>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                            )}
                        </CountUp>
                      </h3>
                      <p>Solicitações de atendimentos regsitradas</p>
                    </div>
                    

                    <a
                      href='https://balcao-virtual-website.azurewebsites.net/'
                      className='btn btn-custom btn-know-more btn-lg page-scroll'
                      target="_blank"
                    >
                      Saiba mais <span className="arrow">→</span>
                    </a>{' '}
                </div>
                  
              </div>

            </div>
          </div>

          <div className='container hello'>
            <div className='row'>
              <div className='col-md-12 hello boxes'>
                <div>
                  <h2>
                      COMPANY <span className="bolder">HELLO</span>
                      <img className='icon' src="/img/hello-icon.png" alt="Company Hello" />{' '}
                  </h2>
                </div>

                <div className="nav-bar-production">
                  <ul>
                    <li>
                      <a id="usuarios-hello-mobile" onClick={backHelloMobile} className='page-scroll active'>
                        USUÁRIOS
                      </a>
                    </li>                    
                    <li>
                      <a id="autores-mobile" onClick={nextHelloMobile} className='page-scroll'>
                        AUTORES
                      </a>  
                    </li>
                  </ul>
                </div>
                  
                <Fade 
                  ref={slideRefHelloMobile} 
                  {...properties}
                  onChange={(e) => {
                    if(e == 1){
                      document.getElementById("usuarios-hello-mobile").classList.add("active");
                      document.getElementById("autores-mobile").classList.remove("active");
                    }
                    else {
                      document.getElementById("usuarios-hello-mobile").classList.remove("active");
                      document.getElementById("autores-mobile").classList.add("active");
                    }
                  }}
                >
                  <div className="each-slide">
                    <div style={{'backgroundImage': `url(${helloImages[0]})`}}></div>
                  </div>
                  <div className="each-slide">
                    <div style={{'backgroundImage': `url(${helloImages[1]})`}}></div>
                  </div>                
                </Fade>

                <div>
                  <div className='text-production'>
                    <p className="bolder">A versão profissional do Company Communicator.</p>
                    <p>O Company Hello transforma a comunicação interna através do uso do Microsoft Teams, modernizando a experiência da comunicação e garantindo o uso do Microsoft Teams como uma poderosa ferramenta de comunicação corporativa.</p>
                  </div>                

                  <div className="metrics-container">
                    <h3 className="metrics-production bolder">
                      <CountUp 
                        className="bolder"
                        start={focusHello ? 0 : null} 
                        end={23700000} 
                        duration={2.5}
                        prefix="+"
                        
                        separator="."
                      >
                          {({ countUpRef }) => (
                              <VisibilitySensor onChange={(isVisible) => {
                                  if (isVisible) { setFocusHello(true); }
                              }}>
                                  <span ref={countUpRef} />
                              </VisibilitySensor>
                          )}
                      </CountUp>
                    </h3>
                    <p>De comunicados enviados</p>
                  </div>

                  <a
                    href='https://hello-website.azurewebsites.net/'
                    className='btn btn-custom btn-know-more btn-lg page-scroll'
                    target="_blank"
                  >
                    Saiba mais <span className="arrow">→</span>
                  </a>{' '}
                </div>
              </div>
                  
            </div>
                
          </div>


          <div className='container sei365'>
            <div className='row'>              
              
              <div className='col-md-12 boxes'>
                
                <div>
                  <h2>
                    <span className="bolder">SEI 365</span>
                    <img className='icon icon-sei' src="/img/sei-icon.png" alt="SEI 365" />{' '}
                  </h2>
                </div>

                {' '}
                <img src="/img/sei-teams.png" alt="SEI365" className="sei-image" />{' '} 

                <div>   
                  <p className="bolder">Inovação e transformação digital para o setor público.</p>               
                  <p>É um software web que integra o SEI - Sistema Eletrônico de Informações (SEI) com a Plataforma Microsoft 365, utilizada por grande parte dos órgãos e instituições públicas brasileiras para controlar digitalmente processos administrativos e documentos com a ajuda de um bot.</p>
                  <a
                    href='https://www.sei365.com.br/'
                    className='btn btn-custom btn-know-more btn-lg page-scroll'
                    target="_blank"
                  >
                    Saiba mais <span className="arrow">→</span>
                  </a>{' '}
                </div>

              </div>            
              
            </div>
          </div>

            
          <div className='container products-info'>
            <div className='row'>
              <div className='col-md-4 boxes'>
                <h3><span className="bolder">Oficinas</span> de trabalho híbrido</h3>
                <p>Empoderando empresas com melhores práticas e recomendações para explorar, com segurança e confiança, todo potencial de colaboração e produtividade do Microsoft 365 para a nova realidade de trabalho.</p>
                <a href="#contact-us">Quero conhecer <span className="arrow">→</span></a>
              </div>
              <div className='col-md-4 boxes'>
                <h3><span className="bolder">Oficina</span> inteligência do trablaho com viva</h3>
                <p>Com o Microsoft Viva é possível simplificar processos e concentrar informações em um só lugar. O grande pilar da inteligencia de trabalho são os colaboradores, com o Viva é possível equilibrar produtividade e bem-estar através de insights poderosos.</p>
                <a href="#contact-us">Saiba mais <span className="arrow">→</span></a>
              </div>
              <div className='col-md-4 boxes'>
                <h3><span className="bolder">Assistente</span> inteligente</h3>
                <p>O Assistente Inteligente (ChatBot) integrado ao Microsoft Teams é uma solução com o objetivo de modernizar a experiência de interação com informações corporativas para os colaboradores, através de um canal moderno, dinâmico e inteligente.</p>
                <a href="#contact-us">Saiba mais <span className="arrow">→</span></a>
              </div>
            </div>
          </div>


        </div>      
    </div>
  )
}
