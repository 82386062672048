import React, { useRef } from "react";

export const Studio = (props) => {
  return (
    <div id='studio'>
      <div className='container container-studio'>
        <div className='row'>
          <div className='col-xs-12 col-md-7 img-1'>
            {' '}
            <img src="/img/studio.png" alt="studio" />{' '}
          </div>
          <div className='col-xs-12 col-md-4 studio'>
            <div className='about-text'>
              <h2>Um <span className="bolder">Studio</span></h2>
              <p>A TOP365 nasceu de grande sonho, contribuir com a transformação digital e inovação dos clientes. Nós trilhamos uma jornada incrível, mas chegou a hora de escrever uma nova história, rumo a novos caminhos, ressiginificando e reimaginando o futuro. Nós somos o Studio 365!</p>
            </div>
            <div className='about-text manifest'>
              <h2><span className="bolder">Manifesto</span></h2>
              <p>Transformar a tecnologia para potencializar a habilidade humana, humanizar as interações e inovar através de soluções que impactem no cotidiano é o que nos direciona.</p>
              <p>Tecnologia, pessoas e intensidade, a tríade composta por forças que se conectam, forças que reimaginam o sentido de criação do Studio.</p>
              <p>Transformamos suas experiências, inovamos o seu presente, conectamos você ao futuro!</p>
            </div>
          </div>
          <div className='col-xs-12 col-md-2 play'>
              <a href="https://youtu.be/YAaj7hN2s4w" target="_blank">
                <img src="/img/play.svg" alt ="play"/>
              </a>
          </div>
        </div>        
      </div>
    </div>    
  )
}
