import React, { useState } from "react";

export const ContactUs = (props) => {
  const { TableClient, AzureSASCredential } = require("@azure/data-tables");
  const account = "studio365";
  const sas = "?sv=2020-08-04&ss=t&srt=o&sp=ac&se=2024-07-01T06:44:37Z&st=2022-04-09T22:44:37Z&spr=https&sig=%2BC4wW%2BZrvhDGBBqx507FAbrUKYPWUPz1Iy6Y4zZQIKg%3D";
  const tableName = "Contato";

  const clientWithSAS = new TableClient(
    `https://${account}.table.core.windows.net`,
    tableName,
    new AzureSASCredential(sas)
  );

  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [empresa, setEmpresa] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [submitted, setSubmitted] = React.useState(null);

  async function insert(e) {
    e.preventDefault();
    
    setSubmitted(true);

    if (!name || !email || !empresa || !message) {
      return;
    } else {
      document.getElementById("submit").innerHTML = "Enviado, obrigado pelo contato! ✅";
      document.getElementById("submit").classList.add("sent");
      
      const testEntity = {
        partitionKey: "studio365",
        rowKey: new Date().valueOf().toString(),
        Nome: name,
        Email: email,
        Empresa: empresa,
        Celular: document.getElementById('celular').value,
        Mensagem: message,
      };

      await clientWithSAS.createEntity(testEntity);
    }
  }

  return (
    <div id='contact-us'>
      <div>
        <picture>
          <source media="(max-width: 768px)" srcSet="img/contact_us_mobile.png"></source>
          <img className="img-bg" src="img/contact_us.png" />
        </picture>
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-7'>
            <h3>ESTAMOS AQUI PARA O QUE </h3>
            <h3 className="bolder">VOCÊ PRECISAR!</h3>

            <p>Deixe aqui seus dados e entraremos em contato.</p>

            <form role="form" className="form-contact-us" onSubmit={(e) => insert(e)}>
              <div className="row">
              <div className={`form-group col-md-12 ${submitted && (!name || name == "") ? "required" : ""}`}>
                  <label htmlFor="name" className="form-label col-form-label-lg">Nome Completo</label>
                  <input type="text" className="form-control" id="name" onChange={(e) => setName(e.target.value)}></input>
                </div>
              </div>
                
              <div className="row">
              <div className={`form-group col-md-12 ${submitted && (!email || email == "") ? "required" : ""}`}>               
                    <label htmlFor="e-mail" className="form-label">Seu e-mail</label>
                    <input type="text" className="form-control" id="email" onChange={(e) => setEmail(e.target.value)}></input>
                </div>
              </div>

              <div className="row">
                <div className={`form-group col-md-6 ${submitted && (!empresa || empresa == "") ? "required" : ""}`}>
                  <label htmlFor="empresa" className="form-label">Empresa</label>
                  <input type="text" className="form-control" id="empresa" onChange={(e) => setEmpresa(e.target.value)}></input>
                </div>

                <div className="form-group col-sm-6">
                  <label htmlFor="celular" className="form-label">Celular</label>
                  <input type="tel" className="form-control" id="celular" aria-describedby="emailHelp"></input>
                </div>                 
                
              </div>

              <div className="row">
                <div className={`form-group col-md-12 ${submitted && (!message || message == "") ? "required" : ""}`}>
                  <label htmlFor="message" className="form-label">Deixe uma mensagem</label>
                  <textarea className="form-control" rows="3" id="message" onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>
              </div>
                
              <div className="row">
              <div className="form-group col-md-12">
                  <button
                    id="submit"
                    className='btn btn-custom btn-send btn-lg page-scroll'
                    type="submit"
                  >
                    Enviar →
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
