export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-5 intro-text'>
                <h1>REIMAGINANDO </h1>
                <h2>A TECNOLOGIA</h2>
                <p>+1 bi usuários impactados</p>
                <a
                  href='#contact-us'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Entre em contato <span className="arrow">→</span>
                </a>{' '}
              </div>
              <div className='col-md-6 banner'>
                <img src="/img/header.png" alt="header" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
